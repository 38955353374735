.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 2.5em;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

button {
  margin: 2em;
}

.box {
  padding: 0 2em;
  font-size: 15px;
}

.subheader {
  background-color: #4e515a;
  padding: 0.3em;
  color: white;
  font-family: "Copperplate", "Copperplate Gothic Light", fantasy;
  text-transform: uppercase;
  font-size: 1.3em;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em;
}

.loading-text {
  font-size: 1.5em;
  margin-top: 0;
}